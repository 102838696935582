var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticStyle: { "background-color": "#f1f2f1" } },
    [
      _c("validation-observer", {
        ref: "editMapForm",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ valid: isFormValid }) {
              return [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "v-toolbar",
                      {
                        ref: "toolbar",
                        staticClass: "elevation-0",
                        attrs: { dark: "", color: "primary" },
                      },
                      [
                        _c("v-toolbar-title", [_vm._v("Edit Map")]),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "", dark: "" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("edit-map-form-close")
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-close")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      {
                        staticClass: "px-8",
                        style: {
                          height: _vm.$vuetify.breakpoint.xsOnly
                            ? `${_vm.contentHeight}px`
                            : "auto",
                          "overflow-y": "auto",
                          "max-height": _vm.$vuetify.breakpoint.xsOnly
                            ? undefined
                            : "60vh",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          { staticClass: "my-1" },
                          [
                            _c(
                              "v-card-text",
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Map Name",
                                    rules: { min: 1, max: 50, required: true },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Map Name",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "name",
                                                id: "mapName",
                                              },
                                              model: {
                                                value: _vm.map.name,
                                                callback: function ($$v) {
                                                  _vm.$set(_vm.map, "name", $$v)
                                                },
                                                expression: "map.name",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "my-1 text-caption" }, [
                          _vm._v("Layers"),
                        ]),
                        _c(
                          "v-card",
                          { attrs: { id: "attachedLayers" } },
                          [
                            _c(
                              "v-card-text",
                              [
                                _c("v-text-field", {
                                  staticClass: "mr-6",
                                  attrs: {
                                    label: "Search",
                                    "hide-details": "auto",
                                    color: "primary",
                                    name: "search",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append",
                                        fn: function () {
                                          return [
                                            _c("v-icon", [
                                              _vm._v(_vm._s(_vm.mdiMagnify)),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: _vm.search,
                                    callback: function ($$v) {
                                      _vm.search = $$v
                                    },
                                    expression: "search",
                                  },
                                }),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mt-4",
                                    attrs: {
                                      color: "primary",
                                      id: "addLayers",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.showAddLayerDialog = true
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", { staticClass: "mr-2" }, [
                                      _vm._v(
                                        " " + _vm._s(_vm.mdiLayersPlus) + " "
                                      ),
                                    ]),
                                    _vm._v(" Add Layers "),
                                  ],
                                  1
                                ),
                                _c("v-data-table", {
                                  attrs: {
                                    headers: _vm.headers,
                                    items: _vm.layers,
                                    "hide-default-footer": "",
                                    "disable-pagination": "",
                                    search: _vm.search,
                                    "custom-filter": _vm.filterLayers,
                                    "item-key": "map_service_id",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "top",
                                        fn: function () {
                                          return [
                                            _vm.selectedLayers.length > 0
                                              ? _c(
                                                  "v-row",
                                                  {
                                                    staticClass: "ml-0 mr-0",
                                                    staticStyle: {
                                                      "background-color":
                                                        "#fff8e1",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-end",
                                                        attrs: { cols: "12" },
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              text: "",
                                                              color: "primary",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.mdiLayersRemove
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " Remove Layers "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "body",
                                        fn: function ({ items }) {
                                          return [
                                            _c(
                                              "draggable",
                                              {
                                                attrs: {
                                                  list: items,
                                                  tag: "tbody",
                                                  handle: ".section-handle",
                                                },
                                                on: {
                                                  end: function ($event) {
                                                    return _vm.onDragEnd(items)
                                                  },
                                                },
                                              },
                                              _vm._l(items, function (item) {
                                                return _c(
                                                  "tr",
                                                  { key: item.map_service_id },
                                                  [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "pa-0 ma-0",
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "section-handle cursor-pointer",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.mdiDragVertical
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.service_name
                                                        )
                                                      ),
                                                    ]),
                                                    _c(
                                                      "td",
                                                      [
                                                        item.is_visible
                                                          ? _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      item.is_visible =
                                                                        !item.is_visible
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.mdiEye
                                                                    )
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      item.is_visible =
                                                                        !item.is_visible
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.mdiEyeOff
                                                                    )
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-right",
                                                      },
                                                      [
                                                        _c(
                                                          "v-menu",
                                                          {
                                                            attrs: {
                                                              "offset-y": "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                    attrs,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  icon: "",
                                                                                  id: "overflowOptions",
                                                                                },
                                                                            },
                                                                            "v-btn",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " mdi-dots-vertical "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "v-list",
                                                              [
                                                                _c(
                                                                  "v-list-item",
                                                                  {
                                                                    staticClass:
                                                                      "cursor-pointer",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeLayer(
                                                                            item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-list-item-icon",
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.mdiLayersRemove
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-list-item-content",
                                                                      {
                                                                        staticClass:
                                                                          "ml-n5",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Remove Layer "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-list-item",
                                                                  {
                                                                    staticClass:
                                                                      "cursor-pointer",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.showEditLayerDialog = true
                                                                          _vm.selectedLayer =
                                                                            item
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-list-item-icon",
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.mdiCog
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-list-item-content",
                                                                      {
                                                                        staticClass:
                                                                          "ml-n5",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Layer Settings "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              }),
                                              0
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: _vm.selectedLayers,
                                    callback: function ($$v) {
                                      _vm.selectedLayers = $$v
                                    },
                                    expression: "selectedLayers",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.departments.length > 0
                          ? _c("AccessForm", {
                              attrs: { selectedMap: _vm.selectedMap },
                              on: {
                                "access-updated": function ($event) {
                                  _vm.newDepartmentsThatHaveAccessToMap = $event
                                },
                              },
                            })
                          : _vm._e(),
                        _c(
                          "v-dialog",
                          {
                            attrs: {
                              "max-width": "600px",
                              persistent: "",
                              fullscreen: _vm.$vuetify.breakpoint.xsOnly,
                            },
                            model: {
                              value: _vm.showAddLayerDialog,
                              callback: function ($$v) {
                                _vm.showAddLayerDialog = $$v
                              },
                              expression: "showAddLayerDialog",
                            },
                          },
                          [
                            _vm.showAddLayerDialog
                              ? _c("AddLayersToMapForm", {
                                  attrs: {
                                    map: _vm.map,
                                    id: "addLayersDialog",
                                  },
                                  on: {
                                    "add-layers-to-map-form-close": function (
                                      $event
                                    ) {
                                      _vm.showAddLayerDialog = false
                                    },
                                    "add-layers-to-map-form-submitted":
                                      _vm.onAddLayersToMapFormSubmitted,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "v-dialog",
                          {
                            attrs: {
                              "max-width": "600px",
                              persistent: "",
                              fullscreen: _vm.$vuetify.breakpoint.xsOnly,
                            },
                            model: {
                              value: _vm.showEditLayerDialog,
                              callback: function ($$v) {
                                _vm.showEditLayerDialog = $$v
                              },
                              expression: "showEditLayerDialog",
                            },
                          },
                          [
                            _vm.showEditLayerDialog
                              ? _c("EditLayerForm", {
                                  attrs: { selectedLayer: _vm.selectedLayer },
                                  on: {
                                    "edit-layer-form-close": function ($event) {
                                      _vm.showEditLayerDialog = false
                                    },
                                    "edit-layer-form-submitted": function (
                                      $event
                                    ) {
                                      _vm.showEditLayerDialog = false
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      {
                        ref: "cardAction",
                        staticClass: "d-flex justify-end align-center pa-5",
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              type: "submit",
                              id: "saveMapBtn",
                              disabled: !isFormValid,
                            },
                          },
                          [_vm._v(" Save Map ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }