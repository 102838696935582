var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        {
          staticClass: "pt-0 page-background mainArea",
          attrs: { fluid: "", tag: "section" },
        },
        [
          _c("TopBar", { attrs: { title: "Maps" } }),
          _c(
            "PageLayout",
            [
              _c("v-data-table", {
                staticClass: "py-0 my-0 cursor-pointer",
                staticStyle: { "overflow-x": "hidden !important" },
                attrs: {
                  headers: _vm.headers,
                  items: _vm.maps,
                  "hide-default-footer": "",
                  search: _vm.search,
                  "disable-pagination": "",
                  "single-select": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "12" } }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-end gap px-2",
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Search",
                                      "hide-details": "auto",
                                      color: "primary",
                                      name: "search",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "append",
                                        fn: function () {
                                          return [
                                            _c("v-icon", [
                                              _vm._v(_vm._s(_vm.mdiMagnify)),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.search,
                                      callback: function ($$v) {
                                        _vm.search = $$v
                                      },
                                      expression: "search",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mt-4",
                                          attrs: {
                                            color: "primary",
                                            id: "addMap",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.showCreateMapDialog = true
                                            },
                                          },
                                        },
                                        [_vm._v(" + Map ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "body",
                    fn: function ({ items }) {
                      return [
                        _c(
                          "tbody",
                          _vm._l(items, function (item) {
                            return _c(
                              "tr",
                              {
                                key: item.map_id,
                                class: {
                                  selectedRow: item === _vm.selectedMap,
                                },
                              },
                              [
                                _c(
                                  "td",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.onMapRowClick(item)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "text-right" },
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: { "offset-y": "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            id: "mapOptionsMenu",
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-dots-vertical "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          { attrs: { id: "mapOptions" } },
                                          [
                                            _c(
                                              "v-list-item",
                                              {
                                                staticClass: "cursor-pointer",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onMapRowClick(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "mr-1" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.mdiPencil) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" Edit Map "),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item",
                                              {
                                                staticClass: "cursor-pointer",
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showConfirmDeleteMapDialog = true
                                                    _vm.mapToDelete = item
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "mr-1" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.mdiDelete) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" Delete Map "),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "600px", persistent: "" },
                  model: {
                    value: _vm.showCreateMapDialog,
                    callback: function ($$v) {
                      _vm.showCreateMapDialog = $$v
                    },
                    expression: "showCreateMapDialog",
                  },
                },
                [
                  _c("CreateMapForm", {
                    on: {
                      "create-map-form-close": function ($event) {
                        _vm.showCreateMapDialog = false
                      },
                      "create-map-form-submitted": function ($event) {
                        _vm.showCreateMapDialog = false
                        _vm.getMaps()
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: {
                    "max-width": "600px",
                    persistent: "",
                    fullscreen: _vm.$vuetify.breakpoint.xsOnly,
                  },
                  model: {
                    value: _vm.showEditMapDialog,
                    callback: function ($$v) {
                      _vm.showEditMapDialog = $$v
                    },
                    expression: "showEditMapDialog",
                  },
                },
                [
                  _c("EditMapForm", {
                    attrs: { selectedMap: _vm.selectedMap },
                    on: {
                      "edit-map-form-close": function ($event) {
                        _vm.showEditMapDialog = false
                        _vm.$router.push({
                          name: "Maps",
                          query: {},
                        })
                      },
                      "edit-map-form-submitted": function ($event) {
                        _vm.showEditMapDialog = false
                        _vm.getMaps()
                        _vm.$router.push({
                          name: "Maps",
                          query: {},
                        })
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: {
                    "max-width": "600px",
                    fullscreen: _vm.$vuetify.breakpoint.xsOnly,
                  },
                  model: {
                    value: _vm.showConfirmDeleteMapDialog,
                    callback: function ($$v) {
                      _vm.showConfirmDeleteMapDialog = $$v
                    },
                    expression: "showConfirmDeleteMapDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c("v-list-item-content", { staticClass: "pl-3" }, [
                            _c("div", { staticClass: "text-h5 py-3" }, [
                              _vm._v("Delete Map?"),
                            ]),
                            _vm._v(
                              " Are you sure you want to delete this map? This action cannot be undone. "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c("v-list-item-content", [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-end" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "", color: "primary" },
                                    on: {
                                      click: function ($event) {
                                        _vm.showConfirmDeleteMapDialog = false
                                        _vm.mapToDelete = undefined
                                      },
                                    },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary" },
                                    on: { click: _vm.deleteMap },
                                  },
                                  [_vm._v(" Delete Map ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }